import './App.css';
import Microphone from './Microphone/Microphone';



function App() {
  return (
    <div className="App">
      <header className="App-header">
      <h1 className='header'>Ask any question to the artificial intelligence</h1>
      </header>
        <Microphone/>
    </div>
  );
}

export default App;
